import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material';
import Endorsement from './Endorsement'

function EndorsementList({ endorsements, hideName }) {
  return (
    <Grid container spacing={0} columns={30}>
      { endorsements.map((endorsement) => (
        <Grid item xs={15} sm={6} key={endorsement.name}>
          <Endorsement hideName={hideName} {...endorsement}></Endorsement>
        </Grid>
      ))}
    </Grid>
  )
}

EndorsementList.propTypes = PropTypes.shape({
  hideName: PropTypes.bool,
  endorsements: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    name: PropTypes.string,
    url: PropTypes.string,
    title: PropTypes.string,
  })),
})

export default EndorsementList
