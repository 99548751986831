import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Link, Typography } from '@mui/material';
import PreviewCompatibleImage from '../PreviewCompatibleImage'
import Caption from '../shared/Caption';

function Endorsement({ image, name, nameColor, url, title, hideName, hideTitle }) {
  const ariaLabel = `Link to endorsement from ${name}`

  return (
    <Box px={2} py={2} textAlign="center">
      <Box marginBottom={1}>
        { url ?
          <Link href={url} target="_blank" underline="none" aria-label={ariaLabel}>
            <PreviewCompatibleImage imageInfo={{...image, alt: name}} />
          </Link> :
          <PreviewCompatibleImage imageInfo={{...image, alt: name}} />
        }
      </Box>
        {!hideName && (
          <Caption
            color={nameColor || "text.primary"}
            gutterBottom
            weight="bold"
            >
            {name}
          </Caption>
        )}
        {!hideTitle && title && (
          <React.Fragment>
            <Typography
              color="text.secondary"
              variant="subtitle2" align="center" sx={{ textTransform: 'uppercase', fontSize: '.8rem' }}>
              {title}
            </Typography>
          </React.Fragment>
        )}
      </Box>
  )
}

Endorsement.propTypes = PropTypes.shape({
  hideName: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
})

export default Endorsement
