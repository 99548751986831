import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import HeroBanner from '../components/HeroBanner'
import EndorsementList from '../components/endorsements-page/EndorsementList'
import { Box, Container, Typography } from '@mui/material';
import { withHelmet } from '../helpers/metadataHelpers';

export const EndorsementsPageTemplate = ({
  title,
  personalEndorsements,
  groupEndorsements,
}) => (
  <section>
    <HeroBanner title={title}/>
    <Container>
      <Box my={4}>
        <Typography gutterBottom variant="h4">
          Personally Endorsed By
        </Typography>
        <EndorsementList endorsements={personalEndorsements} />
      </Box>
      {groupEndorsements.length > 0 && (
        <Box my={4}>
          <Typography gutterBottom variant="h4">
            Also Endorsed By
          </Typography>
          <EndorsementList endorsements={groupEndorsements} hideName={true} />
        </Box>
      )}
    </Container>
  </section>
)

EndorsementsPageTemplate.propTypes = {
  title: PropTypes.string,
  personalendorsements: PropTypes.array,
  groupendorsements: PropTypes.array,
}

const EndorsementsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <EndorsementsPageTemplate
        title={frontmatter.title}
        personalEndorsements={frontmatter.personal_endorsements}
        groupEndorsements={frontmatter.group_endorsements}
      />
    </Layout>
  )
}

EndorsementsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default withHelmet(EndorsementsPage)

export const EndorsementsPageQuery = graphql`
  query EndorsementsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        personal_endorsements {
          name
          url
          title
          quote
          image {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300, fit: COVER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        group_endorsements {
          name
          url
          image {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300, fit: CONTAIN, background: "rgba(0,0,0,0)") {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
